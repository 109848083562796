<template>
  <v-footer
    id="home-footer"
    color="grey darken-4"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
            <template v-for="(s, i) in social">
              <a
                :key="s.name"
                class="white--text pa-1 pa-md-0"
                :href="s.url"
                v-text="s.name"
              />

              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>

        <v-col
          class="text-center"
          cols="12"
          md="6"
        >
          Copyright © {{ new Date().getFullYear() }}  OlapDB.COM, All Rights Reserved.
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        { name: 'English', url: 'https://www.olapdb.com', desc: '英语' },
        { name: '中文', url: 'https://cn.olapdb.com', desc: '中文' },
        // { name: '日本語', url: 'https://jp.olapdb.com', desc: '日语' },
        // { name: 'Deutsch', url: 'https://de.olapdb.com', desc: '德语' },
        // { name: 'Français', url: 'https://fr.olapdb.com', desc: '法语' },
        // { name: 'Español', url: 'https://es.olapdb.com', desc: '西班牙语' },
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
